import { PUBLIC_BASE_URL } from '$env/static/public';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (!PUBLIC_BASE_URL.includes('localhost') && !PUBLIC_BASE_URL.includes('127.0.0.1')) {
	Sentry.init({
		dsn: 'https://f65b29c9c3ff9d2a7d736a70b5062e18@o4506540626477056.ingest.sentry.io/4506540629164032',
		tracesSampleRate: 1.0,
		environment: PUBLIC_BASE_URL.includes('dev') ? 'development' : 'production',

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [new Replay()]
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
